/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import "@fontsource/josefin-sans"
import "@fontsource/berkshire-swash"
import "../css/layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  function Announcement() {
    return (
      <div className="
        bg-pas-orange-dark
        text-pas-white-dark      
        py-2
        px-4 md:px-6
        text-sm md:text-base
        " >
        <div className="font-bold">Announcement</div>
        <div>
          Sorry, due to high demand our next available booking is on Oct 20, 2023.
        </div>
      </div>)
  }

  return (
    <div className="">
      <div className="md:container md:mx-auto lg:max-w-6xl px-[17px] sm:px-3">
        {/* <Announcement></Announcement> */}
        <Header
          siteTitle={data.site.siteMetadata?.title || `Pasteleria NZ`}
        />
        <div className="min-h-[calc(100vh_-_19.5rem)] sm:min-h-[calc(100vh_-_23rem)]">
          <main>{children}</main>
        </div>
        <div id="fb-root"></div>
        <div id="fb-customer-chat" class="fb-customerchat">
        </div>
        <Footer />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout